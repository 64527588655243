import { Component } from '@angular/core';
import { AuthService } from 'shared';

@Component({
  standalone: false,
  selector: 'norby-embed-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  constructor(
    private _auth: AuthService // Kickstart auth
  ) {}
}
