import { Component, OnInit, HostBinding } from '@angular/core';

// 3rd party
import { filter, tap } from 'rxjs/operators';

// App
import {
  DeviceService,
  ContentService,
  SlugService,
  ContentInteractionsService,
  UserService,
  LandingPageStoreService
} from 'shared';
import {
  ContentEvent,
  ISlug,
  isEvent,
  LandingPage,
  ContentLink,
  ContentSignup,
  isSignup,
  ContentRegisterable,
  ContentClick,
  Theme,
  EMBEDS
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'app-embed-overlay-component',
  templateUrl: './embed-overlay-component.component.html',
  styleUrls: ['./embed-overlay-component.component.less']
})
export class EmbedOverlayComponentComponent
  extends BaseComponent
  implements OnInit
{
  @HostBinding('itemId') itemId: string;
  @HostBinding('itemLabel') itemLabel: string;
  @HostBinding('itemType') itemType: string;
  @HostBinding('itemVariant') itemVariant: string;

  drop: ContentSignup;
  event: ContentEvent;
  page: LandingPage;
  slug: ISlug;

  private _hasScrollTriggered = false; // If scroll variant, whether we've launched yet

  get contentTheme(): Theme {
    return this.drop?.theme || this.event?.theme || this.page?.theme || null;
  }

  // Event embed variants
  eventCardVariant = EMBEDS.event.variants.card;
  eventMainVariant = EMBEDS.event.variants.main;
  eventShareVariant = EMBEDS.event.variants.share;

  // Drop embed variants
  dropCardVariant = EMBEDS.drop.variants.card;
  dropMainVariant = EMBEDS.drop.variants.main;
  dropShareVariant = EMBEDS.drop.variants.share;
  dropScrollVariant = EMBEDS.drop.variants.scroll;

  constructor(
    private _device: DeviceService,
    private _content: ContentService,
    private _landingPageStore: LandingPageStoreService,
    private _slug: SlugService,
    private _interactions: ContentInteractionsService,
    private _user: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.itemType === EMBEDS.event.type && !!this.itemId) {
      this._content
        .getContent$(this.itemId)
        .pipe(this.takeUntilDestroy)
        .subscribe((res) => (this.event = isEvent(res) ? res : null));
    } else if (this.itemType === EMBEDS.drop.type && !!this.itemId) {
      this._content
        .getContent$(this.itemId)
        .pipe(this.takeUntilDestroy)
        .subscribe((res) => (this.drop = isSignup(res) ? res : null));
    } else if (this.itemType === EMBEDS.page.type && !!this.itemLabel) {
      this._landingPageStore
        .getLandingPageByLabel$(this.itemLabel)
        .pipe(this.takeUntilDestroy)
        .subscribe((result) => (this.page = result.data));
    }

    this._slug
      .getCurrentSlug$()
      .pipe(this.takeUntilDestroy)
      .subscribe((slug) => (this.slug = slug));

    if (this.itemVariant === 'scroll') {
      this._device.scroll$
        .pipe(
          filter((e) => !this._hasScrollTriggered),
          tap((e) => (this._hasScrollTriggered = true)),
          this.takeUntilDestroy
        )
        .subscribe((e) => this._interactions.registerForContent(this.drop));
    }
  }

  handleEventDropClick(contentClick: ContentClick): void {
    if (!contentClick?.content) return;
    const content = contentClick?.content as ContentRegisterable;
    window.open(content.urls?.pageRaw, '_blank');
  }

  handleContentClick(contentClick: ContentClick) {
    const { content, event } = contentClick ?? {};
    if (!content || !event) {
      return;
    }

    if (content.isLink && content.contentId) {
      const link = content as ContentLink;
      const sentBeacon = this._user.sendBeacon({
        linkId: link.contentId
      });

      if (sentBeacon) {
        this._completeClickthrough(link, event);
      } else {
        this._interactions
          .interactWithLink(link.contentId, 'click')
          .finally(() => this._completeClickthrough(link, event));
      }
    }
  }

  private _completeClickthrough(link: ContentLink, event: Event) {
    if (link.type === 'collection') {
      event.preventDefault();
      const url = `https://${this._device.currentSiteRootURL}/${link?.url}`;
      window.open(url, '_blank');
    }
  }
}
